.ContactPageContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  align-items: center;
}

.ContactPageInternalContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  z-index: 100;
  align-items: flex-start;
  width: 100%;
  max-width: var(--page-max-width);
  margin-top: 64px;
}

.ContactPageHeading {
  display: flex;
  font-weight: bold;
  color: var(--light-green);
  font-size: 1.5rem;
  text-align: left;
  margin: 0px;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
}

.ContactPageText {
  display: flex;
  color: var(--primary-text);
  font-size: 1.1rem;
  margin: 0px;
  padding: 0px;
  flex-wrap: wrap;
  line-height: 1.6;
  font-weight: var(--primary-text-font-weight);
  margin-top: 12px;
}

.ContactPageButtonsContainer {
  display: grid;
  max-width: 500px;
  margin-top: 36px;
}

@media (max-width: 650px) {
  .ContactPageButtonsContainer {
    grid-template-columns: repeat(3, 120px);
    grid-column-gap: 8px;
    grid-row-gap: 16px;
  }
}

@media (min-width: 651px) {
  .ContactPageButtonsContainer {
    grid-template-columns: repeat(6, 120px);
    grid-gap: 16px;
  }
}

@media (max-width: 1200px) {
  .ContactPageContainer {
    padding-left: 24px;
    padding-right: 24px;
  }
}
