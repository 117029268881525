.AboutMeCardContainer {
  width: 100vw;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  margin-top: 24px;
}

.AboutMeCardImgContainer {
  float: right;
  width: 40vw;
  max-width: 400px;
  max-height: 550px;
  padding-left: 12px;
}
.AboutMeCardImg {
  object-fit: cover;
  width: 40vw;
  max-width: 400px;
  max-height: 550px;
  object-position: center bottom;
  border-radius: 8px;
  margin: 0px;
  padding: 0px;
}

.AboutMeCardHeading {
  font-weight: bold;
  color: var(--light-green);
  font-size: 1.5rem;
  text-align: left;
  margin: 0px;
  margin-top: 12px;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
}

.AboutMeCardDesc {
  color: var(--primary-text);
  font-size: 1.1rem;
  margin: 0px;
  padding: 0px;
  flex-wrap: wrap;
  line-height: 1.6;
  font-weight: var(--primary-text-font-weight);
  margin-top: 12px;
  text-align: justify;
  padding-right: 24px;
}

@media (max-width: 1200px) {
  .AboutMeCardHeading {
    padding-left: 24px;
  }
}

@media (max-width: 1200px) {
  .AboutMeCardDesc {
    padding-left: 24px;
  }
}

@media (max-width: 1200px) {
  .AboutMeCardImgContainer {
    padding-right: 24px;
  }
}
