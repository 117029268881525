.TextHeadingContainer {
  display: flex;
  max-width: var(--page-max-width);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.TextHeadingText {
  display: flex;
  color: var(--primary-text);
  font-size: 3rem;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

.TextHeadingLine {
  display: flex;
  flex: 1;
  background-color: var(--primary-text);
  height: 3px;
  margin: 0px;
  padding: 0px;
  margin-left: 24px;
}
