.ImageWithFooterContainer {
  display: flex;
  flex-direction: column;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  margin-top: 24px;
  align-items: center;
}
.ImageWithFooterImage {
  display: flex;
  max-width: 70%;
  border-radius: 4px;
}

.ImageWithFooterText {
  display: flex;
  color: var(--light-gray);
  font-size: 1.1rem;
  margin: 0px;
  margin-top: 4px;
  text-align: center;
}
