.BlogSnippetGridContainer {
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  max-width: var(--page-max-width);
}

.GridContainer {
  margin: 0px;
  padding: 0px;
  display: grid;
}

@media (min-width: 650px) {
  .GridContainer {
    grid-template-columns: repeat(1, 80vw);
  }
}

@media (min-width: 651px) {
  .GridContainer {
    grid-template-columns: repeat(2, 300px);
    grid-gap: 16px;
  }
}

@media (min-width: 1124px) {
  .GridContainer {
    grid-template-columns: repeat(3, 300px);
    grid-gap: 16px;
  }
}

@media (min-width: 1324px) {
  .GridContainer {
    grid-template-columns: repeat(4, 300px);
    grid-gap: 16px;
  }
}
