.TagContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
}

.TagName {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  text-decoration-line: none;
}

.TagName:hover {
  text-decoration-line: underline;
  margin: 0;
  padding: 0;
}

.TagIcon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
