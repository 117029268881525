.BlogSnippetImmersiveItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  margin: 0px;
  padding: 0px;
  padding: 24px;
  border-radius: 8px;
  text-decoration: none;
}
.BlogSnippetImmersiveItem:hover {
  cursor: pointer;
  background-color: rgb(77, 77, 77, 0.3);
}

.BlogSnippetImmersiveItem:hover .BlogSnippetImmersiveHeadingText {
  color: var(--accent-color);
}

.BlogSnippetImmersiveHeadingText {
  display: flex;
  font-weight: bold;
  color: var(--primary-text);
  font-size: 1.8rem;
  text-align: left;
  margin: 0px;
  flex-wrap: wrap;
}

.BlogSnippetImmersiveDescriptionText {
  display: flex;
  color: var(--primary-text);
  font-size: 1rem;
  text-align: left;
  margin: 0px;
  margin-top: 12px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  margin-bottom: 12px;
  font-weight: var(--primary-text-font-weight);
}

.BlogSnippetImmersiveTimeText {
  display: flex;
  color: rgb(170, 170, 170);
  font-size: 0.9rem;
  text-align: left;
  margin: 0px;
  margin-top: 8px;
}
