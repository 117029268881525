.AboutMePageContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  align-items: center;
}

.AboutMePageContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  flex: 1;
}
