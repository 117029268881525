:root {
  --primary-color: #0e141b;
  --secondary-color: #1c252e;
  --accent-color: #ff0a78;
  --light-green: #52d273;
  --light-gray: #b3b8bc;
  --primary-text: #f2f5f7;
  --secondary-text: #aaaaaa;
  --primary-text-50: #f2f5f762;
  --highlight: #182635;
  --transparent: #ffffff00;

  --primary-text-font-weight: 500;
  --page-header-height: 100px;
  --page-max-width: 1200px;
}

@media (max-width: 650px) {
  :root {
    --page-header-height: 70px;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.page-content {
  display: flex;
  flex: 1;
  margin-top: var(--page-header-height);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
