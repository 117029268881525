.CardItem {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 360px;
  background-color: rgb(34, 34, 34);
  border-radius: 4px;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
}
.CardItem:hover {
  cursor: pointer;
}

@media (max-width: 650px) {
  .CardItem {
    display: flex;
    margin-bottom: 16px;
  }
}

.CoverImageContainer {
  display: flex;
  width: 350px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
}

.CoverImage {
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: transform 0.3s;
}

.CoverImage:hover {
  transform: scale(1.03);
}

.TimeText {
  display: flex;
  color: rgb(170, 170, 170);
  font-size: 0.8rem;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0px;
  margin-top: 16px;
}

.HeadingText {
  display: flex;
  font-weight: bold;
  color: var(--primary-text);
  font-size: 1.2rem;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0px;
  margin-top: 12px;
  flex-wrap: wrap;
}

.DescriptionText {
  display: flex;
  color: var(--secondary-text);
  font-size: 1rem;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0px;
  margin-top: 12px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  margin-bottom: 12px;
  font-weight: var(--primary-text-font-weight);
}

.TagWidgetContainer {
  margin-right: 5px;
}

.TagWidgetsContainer {
  display: flex;
  flex-direction: row;
  padding-left: 24px;
  padding-right: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
