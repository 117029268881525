.BlogContentTextContainer {
  display: flex;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  width: 100vw;
  margin-top: 16px;
}

.BlogContentTextDiv {
  display: flex;
  width: 100%;
}

@media (max-width: 1200px) {
  .BlogContentTextDiv {
    padding-left: 24px;
    padding-right: 24px;
  }
}
