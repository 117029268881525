.CursorTextContainer {
  display: flex;
  flex-direction: row;
}
.Cursor {
  display: flex;
  color: var(--primary-text);
  animation: blinkingText 1s infinite;
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}

.CursorText {
  display: flex;
  font-weight: 500;
  color: var(--accent-color);
  font-size: 1.1rem;
  margin: 0px;
  padding: 0px;
}

@keyframes blinkingText {
  0% {
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
