.BlogPageDescriptionContainer {
  display: flex;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  margin-top: 16px;
}

.BlogPageDescriptionText {
  display: flex;
  width: 100%;
  color: var(--primary-text);
  font-size: 1.1rem;
  text-align: left;
  margin: 0px;
  flex-wrap: wrap;
  font-weight: var(--primary-text-font-weight);
}

@media (max-width: 1200px) {
  .BlogPageDescriptionText {
    padding-left: 24px;
    padding-right: 24px;
  }
}
