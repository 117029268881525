.BlogSnippetImmersiveListContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  max-width: var(--page-max-width);
  margin-top: 3em;
}

.BlogSnippetImmersiveContainer {
  display: flex;
  margin: 0px;
  padding: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: var(--page-max-width);
}

@media (max-width: 1200px) {
  .BlogSnippetImmersiveContainer {
    padding-left: 24px;
    padding-right: 24px;
  }
}
