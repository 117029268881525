.BlogContentHighlightContainer {
  display: flex;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  width: 100vw;
  margin-top: 16px;
  margin-bottom: 16px;
}

.BlogContentHighlightDiv {
  display: flex;
  width: 100%;
}

.BlogContentBubbleContainer {
  background-color: var(--highlight);
  padding: 24px;
  border-radius: 8px;
}

@media (max-width: 1200px) {
  .BlogContentHighlightDiv {
    padding-left: 24px;
    padding-right: 24px;
  }
}
