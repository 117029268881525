.Container {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  align-items: center;
}

.ContentConatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
}

.WaveContainer {
  position: absolute;
  z-index: -1;
  margin: 0px;
  padding: 0px;
  position: fixed;
}

.HomeNewsLetterContainer {
  display: flex;
  width: 100%;
  max-width: var(--page-max-width);
}

.LottieDeveloper {
  display: none;
}

@media (min-width: 1920px) {
  .LottieDeveloper {
    display: flex;
    position: absolute;
    left: calc(100vw - 400px);
    width: 350px;
    margin-top: -50px;
    margin-bottom: -50px;
    margin-right: -1000px;
  }
}
