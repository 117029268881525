.BlogContentHeadingContainer {
  display: flex;
  width: 100vw;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  margin-top: 24px;
}

.BlogContentHeadingText {
  display: flex;
  font-weight: bold;
  width: 100%;
  color: var(--light-green);
  font-size: 1.5rem;
  text-align: left;
  margin: 0px;
  margin-top: 12px;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .BlogContentHeadingText {
    padding-left: 24px;
    padding-right: 24px;
  }
}
