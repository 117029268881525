.NotFoundContainer {
  display: flex;
  max-width: var(--page-max-width);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
}

.NotFoundText {
  display: flex;
  color: var(--primary-text);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.StatusCodeText {
  display: flex;
  color: var(--light-green);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  margin-top: 36px;
}

.NotFoundLottie {
  max-width: 300px;
}

@media (max-width: 1200px) {
  .NotFoundContainer {
    padding-left: 12px;
    padding-right: 12px;
  }
}
