.NewsLetterContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 8px;
}

.NewsLetterContainerStaggered {
  padding: 24px;
  margin-top: 24px;
  background-color: rgb(34, 34, 34);
}

.NewsLetterContainerImmersive {
  background-color: transparent;
  margin-top: 64px;
}

@media (max-width: 1200px) {
  .NewsLetterContainerImmersive {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.NewsLetterHeadingText {
  display: flex;
  font-weight: bold;
  color: var(--primary-text);
  font-size: 1.5rem;
  text-align: left;
  margin: 0px;
  margin-bottom: 4px;
  flex-wrap: wrap;
}

.NewsLetterSubHeadingContainer {
  display: flex;
  flex-direction: row;
}

.NewsLetterSubHeadingText {
  display: flex;
  font-weight: 500;
  color: var(--primary-text);
  font-size: 1rem;
  text-align: left;
  margin: 0px;
  flex-wrap: wrap;
  margin-bottom: 18px;
  margin-right: 6px;
  max-width: 800px;
}

.NewsLetterFormContainer {
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  align-items: flex-end;
}

.NewsLetterTextContainer {
  display: flex;
  margin-bottom: 24px;
  max-width: 800px;
}
.NewsLetterText {
  display: flex;
  flex: 1;
}

.NewsLetterButton {
  width: 160px;
}

.NewsLetterButtonContainer {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-left: 24px;
}

@media (max-width: 650px) {
  .NewsLetterButtonContainer {
    display: none;
  }
}

.NewsLetterButtonContainerMobile {
  display: none;
}

@media (max-width: 650px) {
  .NewsLetterButtonContainerMobile {
    display: flex;
    align-self: center;
  }
}

.NewsLetterImmersiveFormSpacing {
  margin-right: 24px;
}
