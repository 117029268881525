.HighlightItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
}
.HighlightItem:hover {
  cursor: pointer;
}

.CoverImageContainer {
  display: flex;
  width: 100%;
  object-fit: fill;
  overflow: hidden;
  overflow: hidden;
}

.HighlightCoverImage {
  width: 100%;
  object-fit: fill;
  transition: transform 0.3s;
}

.HighlightCoverImage:hover {
  transform: scale(1.03);
}

.HighlightHeadingText {
  display: flex;
  font-weight: bold;
  color: var(--primary-text);
  font-size: 1.2rem;
  text-align: left;
  margin: 0px;
  margin-top: 12px;
  flex-wrap: wrap;
}

.HighlightDescriptionText {
  display: flex;
  color: var(--secondary-text);
  font-size: 1rem;
  text-align: left;
  margin: 0px;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  margin-bottom: 12px;
  font-weight: var(--primary-text-font-weight);
}

.HighlightTimeText {
  display: flex;
  color: var(--primary-text);
  font-size: 0.8rem;
  text-align: left;
  margin: 0px;
  margin-top: 12px;
}
