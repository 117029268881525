.PageFooterContainer {
  display: flex;
  width: 100vw;
  background-color: var(--secondary-color);
  margin-top: 100px;
  align-items: center;
  justify-content: center;
}

.PageFooterContentContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: var(--page-max-width);
  padding: 24px;
}

.PageFooterLeftContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.PageFooterLogo {
  display: flex;
  margin: 0px;
  padding: 0px;
  width: 200px;
}

@media (max-width: 800px) {
  .PageFooterLogo {
    width: 120px;
  }
}

.PageFooterSubtitleText {
  display: flex;
  color: var(--primary-text);
  font-size: 1rem;
  margin: 0px;
  text-decoration-line: none;
  margin-top: 8px;
  flex: 1;
}

.PageFooterBuiltWithText {
  display: flex;
  color: var(--primary-text);
  font-size: 1.2rem;
  text-decoration-line: none;
}

.PageFooterRightContainer {
  display: flex;
  flex-direction: column;
  padding-right: 36px;
}

.PageFooterLinksHeadingText {
  display: flex;
  color: rgb(180, 179, 179);
  font-size: 1rem;
  margin: 0px;
  text-decoration-line: none;
}

.PageFooterLinksText {
  display: flex;
  color: var(--primary-text);
  font-size: 1.1rem;
  margin: 0px;
  text-decoration-line: none;
  margin-top: 8px;
}

.PageFooterFlatIconLink {
  display: flex;
  color: var(--primary-text);
  font-size: 1rem;
  margin: 0px;
  text-decoration-line: none;
}

.PageFooterFlatIconLink:hover {
  color: var(--accent-color);
}

.PageFooterLinksText:hover {
  color: var(--accent-color);
}
