.TopMargin {
  height: 50px;
  background-color: var(--secondary-color);
}

@media (min-width: 1400px) {
  .TopMargin {
    height: 0px;
    margin-top: -150px;
  }
}
