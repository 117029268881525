.CodeBlockContainer {
  display: flex;
  width: 90vw;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
}
