.HighlightsStaggeredContainer {
  display: flex;
  max-width: var(--page-max-width);
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  width: 90vw;
}

@media (min-width: 650px) {
  .HighlightWithFocusContainer {
    width: 80vw;
  }
}
@media (min-width: 1324px) {
  .HighlightWithFocusContainer {
    flex: 3;
  }
}

.HighlightFocus {
  display: flex;
}

.HighlightFocusOthersContainer {
  display: flex;
  margin-top: 36px;
}

.HighlightFocusOthers {
  display: flex;
  flex: 1;
}

.HighlightFocusOthersDivider {
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  height: 200px;
  align-self: center;
}

.HighlightFocusOthersContainerRightOptional {
  display: none;
}
@media (max-width: 1323px) {
  .HighlightFocusOthersContainerRightOptional {
    display: flex;
    margin-top: 36px;
  }
}

.HighlightRightOthersContainer {
  display: none;
}

@media (min-width: 1324px) {
  .HighlightRightOthersContainer {
    visibility: visible;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: baseline;
    padding-left: 24px;
  }
}

.HighlightRightOthers {
  display: flex;
}
