.BlogContentListItemContainer {
  display: flex;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  width: 100vw;
  margin-top: 8px;
}

.BlogContentULStyle {
  list-style-type: disc;
  color: var(--primary-text);
}

.BlogContentListItemDiv {
  display: flex;
}

@media (max-width: 1200px) {
  .BlogContentListItemContainer {
    width: calc(100vw - 24px);
  }
}
