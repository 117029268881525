.PageHeaderContainer {
  display: flex;
  background-color: var(--secondary-color);
  margin: 0px;
  padding: 0px;
  width: 100%;
  justify-content: center;
  flex: 1;
  align-items: center;
  height: var(--page-header-height);
  scrollbar-gutter: stable;
  position: fixed;
  z-index: 101;
}

.PageHeaderContent {
  display: flex;
  flex: 1;
  max-width: var(--page-max-width);
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.PageLeftContainer {
  display: flex;
  flex: 1;
  margin: 0px;
  padding: 0px;
  align-items: center;
  text-decoration: none;
}

.Link {
  text-decoration: none;
}
@media (max-width: 1200px) {
  .PageLeftContainer {
    margin-left: 24px;
  }
}

.PageHeaderLeftLogo {
  display: flex;
  height: 80%;
  margin: 0px;
  padding: 0px;
  object-fit: scale-down;
  width: 250px;
  align-self: center;
}

.PageHeaderLeftLogo:hover {
  cursor: pointer;
}

.PageCenterContaier {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PageHeaderCenterText {
  display: none;
}
@media (min-width: 800px) {
  .PageHeaderCenterText {
    display: flex;
    font-weight: bold;
    color: var(--primary-text);
    font-size: 1.7rem;
    padding-left: 14px;
    padding-right: 14px;
    margin: 0px;
    flex-wrap: wrap;
    text-decoration-line: none;
  }
}
@media (min-width: 800px) {
  .PageHeaderCenterText:hover {
    cursor: pointer;
    color: var(--accent-color);
    margin: 0;
    padding: 0;
    padding-left: 14px;
    padding-right: 14px;
    margin: 0px;
  }
}

.PageRightContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: right;
}

@media (min-width: 800px) {
  .PageHeaderHamburgerIconContainer {
    display: none;
  }
}

.PageHeaderHamburgerIconContainer {
  width: 32px;
  height: 32px;
  margin-right: 24px;
}

.PageHeaderHamburgerIcon {
  width: 32px;
  height: 32px;
}

.PageHeaderHamburgerIcon:hover {
  cursor: pointer;
}

.PageDrawerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.PageDrawerItem {
  cursor: pointer;
  font-weight: bold;
  color: var(--primary-text);
  font-size: 1.3rem;
  padding-left: 36px;
  padding-right: 36px;
  margin: 0px;
  height: 48px;
  text-decoration-line: none;
  line-height: 48px;
}
