.BlogPageTimeContainer {
  display: flex;
  width: 100vw;
  max-width: var(--page-max-width);
  margin: 0px;
  padding: 0px;
  margin-top: 24px;
}

.BlogPageTimeText {
  display: flex;
  width: 100%;
  color: var(--light-gray);
  font-size: 1rem;
  text-align: left;
  margin: 0px;
  padding: 0px;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .BlogPageTimeText {
    margin-left: 24px;
  }
}
