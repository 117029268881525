.BlogPageContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  align-items: center;
}

.BlogPageContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  flex: 1;
}

.BlogPageNewsLetterContainer {
  display: flex;
  width: 100%;
  max-width: var(--page-max-width);
}
