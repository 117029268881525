.PrivacyPageContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  align-items: center;
}

.PrivacyPageContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  flex: 1;
}

.PrivacyPolicyText {
  max-width: var(--page-max-width);
}

.PrivacyPolicyText {
  color: var(--primary-text);
}

@media (max-width: 1200px) {
  .PrivacyPageContainer {
    padding-left: 24px;
    padding-right: 24px;
  }
}
