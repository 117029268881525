.CustomTextContainer {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.CustomTextText {
  display: flex;
  color: var(--primary-text);
  font-size: 1.1rem;
  margin: 0px;
  padding: 0px;
  flex-wrap: wrap;
  line-height: 1.6;
  font-weight: var(--primary-text-font-weight);
}
